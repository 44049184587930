<template>
  <b-modal
    :visible="sidebarStatus"
    hide-header
    hide-footer
    @change="(val) => $emit('updateSidebarStatus', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t('zonesModel.title') }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <div v-if="zoneData">
        <!-- Form: Personal Info Form -->
        <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit(zoneData))"
            @reset.prevent="resetForm"
          >
            <!-- Field: Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group :label="$t('zonesModel.name')" label-for="name">
                <b-form-input
                  v-model="zoneData.name"
                  class="form-control"
                  :placeholder="$t('zonePlaceholders.name')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Field: Is Active -->
            <b-col>
              <b-row align-h="start">
                <p class="mr-1" style="font-weight: bold">
                  {{ $t('zonesModel.isActive') }}
                </p>
                <b-form-checkbox
                  v-model="zoneData.isActive"
                  name="check-button"
                  switch
                >
                  <span style="font-weight: 500">
                    {{
                      zoneData.isActive
                        ? $t('commons.switch.yes')
                        : $t('commons.switch.no')
                    }}
                  </span>
                </b-form-checkbox>
              </b-row>
            </b-col>

            <!-- Field: Province -->
            <validation-provider
              #default="validationContext"
              name="Province"
              rules="required"
            >
              <b-form-group
                :label="$t('zonesModel.province')"
                label-for="Province"
              >
                <v-select
                  id="province"
                  :disabled="true"
                  :options="PROVINCES"
                  :clearable="false"
                  v-model="zoneData.province"
                  placeholder="Province"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Field: City -->
            <validation-provider
              #default="validationContext"
              name="City"
              rules="required"
            >
              <b-form-group :label="$t('zonesModel.city')" label-for="City">
                <v-select
                  id="city"
                  :options="CITIES"
                  :clearable="false"
                  v-model="zoneData.city"
                  :placeholder="$t('zonePlaceholders.city')"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-row class="mt-2 pr-1" align-h="end">
              <b-button
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                variant="outline-secondary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="hide"
              >
                {{ $t('buttons.cancel') }}
              </b-button>
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                type="submit"
              >
                <b-spinner
                  v-if="loading"
                  small
                  :label="$t('commons.loading')"
                />
                <span v-else>{{ $t('buttons.save') }}</span>
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <SpinnerLoading v-else />
    </template>
  </b-modal>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BModal,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import zonesStoreModule from './zonesStoreModule'
import ZoneModel from '@/models/zone.model'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import SpinnerLoading from '@/components/SpinnerLoading.vue'
import i18n from '@/libs/i18n/index'
import { localize } from 'vee-validate'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BModal,
    BSpinner,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
    SpinnerLoading,
  },

  computed: {
    PROVINCES() {
      return this.$store.state.system.config.provinceOptions
    },

    CITIES() {
      return this.$store.state.system.config.cityOptions
    },
    loading() {
      return this.$store.state['app-zones'].loading
    }
  },
  props: {
    sidebarStatus: {
      type: Boolean,
      required: true,
    },
    editItem: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      required,
    }
  },
  watch: {
    editItem(val) {
      this.zoneData = new ZoneModel(val)
    },
    '$i18n.locale': function (newVal, oldVal) {
      localize(newVal)
    },
  },
  setup(props, { emit }) {
    const blankzoneData = new ZoneModel({
      province: 'MALAGA',
      isActive: false,
    })
    const zoneData = ref(new ZoneModel(blankzoneData))

    const zone_APP_STORE_MODULE_NAME = 'app-zones'

    const resetZoneData = () => {
      zoneData.value = new ZoneModel(blankzoneData)
    }

    const toast = useToast()

    // Register module
    if (!store.hasModule(zone_APP_STORE_MODULE_NAME)) {
      store.registerModule(zone_APP_STORE_MODULE_NAME, zonesStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(zone_APP_STORE_MODULE_NAME))
        store.unregisterModule(zone_APP_STORE_MODULE_NAME)
    })

    const onSubmit = (data) => {
      store
        .dispatch('app-zones/setZone', data)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.save.success'),
              icon: 'CheckIcon',
              variant: 'success',
            },
          })

          emit('refetch-data')
          emit('updateSidebarStatus', false)
          zoneData.value = blankzoneData
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: i18n.t('toasts.save.error'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetZoneData)

    return {
      zoneData,
      refFormObserver,
      getValidationState,
      resetForm,

      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
